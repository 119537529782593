<template>
  <div
    :class="{ 'fill-height': $vuetify.breakpoint.xsOnly }"
    class="patient-chat-start-dialog-container d-flex flex-column"
  >
    <div class="d-flex justify-space-between align-center full-width mb-4">
      <div class="title">{{ $t("chat.startConversationModal.title") }}</div>
      <v-btn class="close-btn" icon @click="close">
        <img alt="Close" src="@/assets/icons/close-light.svg" />
      </v-btn>
    </div>
    <div class="d-flex align-center text chief-wd mb-2">
      <span>Choose a doctor</span>
    </div>
    <div class="mb-4">
      <v-autocomplete
        v-model="participantId"
        :disabled="usersLoading"
        :filter="customFilter"
        :items="participantOptions"
        :loading="usersLoading"
        append-icon="mdi-menu-down"
        class="participant-select"
        dense
        height="46"
        hide-details
        outlined
      >
        <template v-slot:selection="data">
          <v-chip class="px-1" color="white">
            <Avatar :src="data.item.photoURL" :userId="data.item.value" class="mr-1" />
            {{ data.item.name }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <template>
            <v-list-item-avatar>
              <Avatar :src="data.item.photoURL" :userId="data.item.value" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ data.item.name }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </div>
    <div class="chief d-flex align-center mb-4">
      <div class="d-flex align-center text chief-wd mr-1">
        <span>{{ $t("visits.chiefComplaint") }}</span>
      </div>
      <div class="chief-select">
        <v-autocomplete
          v-model="chiefComplaint"
          :items="chiefComplaints"
          :multiple="false"
          append-icon="mdi-menu-down"
          dense
          height="46"
          hide-details
          outlined
        >
          <template v-slot:selection="data">
            <v-chip class="px-1" color="white">
              {{ data.item.text }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.text }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </div>
    </div>
    <div class="mb-7">
      <v-textarea
        v-model="complaintDescription"
        :label="$t('chat.visitReason')"
        auto-grow
        hide-details
        outlined
        row-height="20"
        rows="5"
      />
    </div>
    <div v-if="userIsPatient" class="d-flex align-center mb-7">
      <v-checkbox v-model="checkbox" class="mr-1 mt-0 pt-0" dense hide-details />
      <i18n class="subtext" path="chat.startConversationModal.confirmTxt" tag="span">
        <span class="link" @click.prevent="medicalInformationLink">{{
          $t("chat.startConversationModal.medicalInformation")
        }}</span>
      </i18n>
    </div>
    <div>
      <v-btn :disabled="!enabledFinish" :loading="finishLoading" block color="primary" depressed @click="finish">
        {{ $t("chat.startConversationModal.start") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import Vue from "vue";

import Avatar from "@/components/Avatar/Index.vue";
import DialogNamesEnum from "@/components/Chat/Enums/dialogNamesEnum";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";
import { useChatStore } from "@/pinia-store/chat";
import { usePatientStore } from "@/pinia-store/patient";
import { usePatientsStore } from "@/pinia-store/patients";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";
import { useRegistrarsStore } from "@/pinia-store/registrars";
import { RolesEnum } from "@/types/Roles.enum";

export default Vue.extend({
  name: "PatientStartNewConversation",
  components: {
    Avatar,
  },
  inject: ["injectable"],
  data() {
    return {
      twilioContext: this.injectable.twilioContext,
      participantId: null,
      finishLoading: false,
      usersLoading: false,
      storeLoaded: false,
      checkbox: false,
      chiefComplaint: null,
      complaintDescription: null,
      practitionersFullData: [],
      registrarChatParticipants: [],
    };
  },
  computed: {
    ...mapState(useAuthStore, ["role", "userIsPatient", "userIsPractitioner"]),
    ...mapState(useAuthStore, ["uid", "timeZone"]),
    ...mapState(useAuthStore, ["role", "userIsPatient", "userIsPractitioner", "userIsRegistrar", "userIsAdmin"]),
    ...mapState(useChatStore, ["startConversationParticipants"]),
    ...mapState(usePatientStore, ["patient"]),
    ...mapState(usePatientsStore, ["practitionerOwnPatients"]),
    ...mapState(useRegistrarsStore, ["registrarPractitioners"]),
    ...mapState(useProfileSettingsStore, ["patientSettings", "practitionerSettings"]),
    chiefComplaints() {
      const chiefComplaints = this.$t("general.chiefComplaints");
      if (!chiefComplaints) {
        return [];
      }
      return Object.keys(chiefComplaints).map((key) => ({ text: chiefComplaints[key], value: chiefComplaints[key] }));
    },
    enabledFinish() {
      return this.checkbox && this.chiefComplaint.length && this.participantId && this.complaintDescription.length;
    },
    participantOptions() {
      return this.startConversationParticipants.map((user) => ({
        name: `${user.role === RolesEnum.Practitioner && "Dr. "}${user.displayName} ${
          user.role === RolesEnum.Practitioner && user.qualification ? `, ${user.qualification}` : ""
        }`,
        photoURL: user.photoURL,
        value: user.id,
      }));
    },

    userProfile() {
      if (this.userIsPatient) {
        return this.patientSettings;
      } else if (this.userIsPractitioner) {
        return this.practitionerSettings;
      } else return null;
    },
    participantProfile() {
      if (this.userIsPatient) {
        return this.practitionersFullData.find((practitioner) => practitioner.id === this.participantId);
      } else if (this.userIsPractitioner) {
        return this.practitionerOwnPatients.find((patient) => patient.id === this.participantId);
      } else return null;
    },
  },
  methods: {
    ...mapActions(useChatStore, ["getStartConversationParticipants"]),
    ...mapActions(usePatientsStore, ["getAllByPractitionerId"]),
    ...mapActions(usePatientStore, ["getPatientProfile"]),
    ...mapActions(useProfileSettingsStore, ["getPatientSettings", "getPractitionerSettings"]),
    ...mapActions(useRegistrarsStore, ["getRegistrarPractitioners"]),
    close() {
      this.checkbox = false;
      this.friends = null;
      this.chiefComplaint = null;
      this.$emit("close");
    },
    customFilter(item, queryText) {
      const name = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return name.indexOf(searchText) > -1;
    },
    async finish() {
      this.finishLoading = true;
      const chatCreateRequest = {
        userTimeZone: this.timeZone,
        participantProfile: this.startConversationParticipants.find((i) => i.id === this.participantId),
        isFloatingChat: false,
        dialogType: DialogNamesEnum.MedicalDialog,
        dialogData: { complaintDescription: this.complaintDescription, chiefComplaint: this.chiefComplaint },
        userProfile: this.userProfile,
        isAnonymous: false,
        participantId: this.participantId,
      };
      await this.injectable.twilioContext.createNewConversation(chatCreateRequest);
      await this.getStartConversationParticipants();
      this.finishLoading = false;
      this.close();
    },
    async medicalInformationLink() {
      const routeData = this.$router.resolve({ name: "patientSettings" });
      window.open(routeData.href, "_blank");
    },
  },
  async created() {
    this.usersLoading = true;

    try {
      await this.getPatientProfile();
      await this.getStartConversationParticipants();
    } catch (err) {
      console.error(err);
      snackBarEventBus.$emit(snackBarEventName, { message: err.message || err, type: "error" });
    } finally {
      this.usersLoading = false;
    }
  },
  watch: {
    participantOptions() {
      if (this.userIsPatient) {
        if (!this.patient?.practitioner) {
          return;
        }
        this.participantId = this.patient.practitioner.id;
      }
    },
  },
});
</script>

<style lang="scss">
.patient-chat-start-dialog-container {
  background-color: white;
  padding: 25px 31px 25px 25px;

  .participant-select {
    &.v-autocomplete.v-select.v-input--is-focused input {
      min-width: auto !important;
    }
  }

  .title {
    font-weight: 500;
    font-size: 16px;
    color: $primaryblack;
    position: relative;
    font-style: normal;
    line-height: 20px;

    .close-btn {
      position: absolute !important;
      top: 17px;
      right: 16px;
      width: 26px;
      height: 26px;
    }
  }

  .text {
    font-weight: 500;
    font-size: 14px;
    color: $primaryblack2;
    mix-blend-mode: normal;
    opacity: 0.88;
  }

  .subtext {
    font-size: 13px;
    color: $primaryblack2;
    mix-blend-mode: normal;
    opacity: 0.88;
  }

  .chief {
    height: 46px;
    display: flex;
    max-width: 100%;

    &-wd {
      width: 120px;
    }

    &-select {
      width: 70%;
      display: block;
    }
  }

  .link {
    font-weight: 500;
    color: $primary;
    cursor: pointer;
  }
}
</style>
