<template>
  <div>
    <Confirm
      :confirmText="$t('general.update')"
      :dialog="value"
      :loading="loading"
      cancelText="Close"
      rootClass="media-rename"
      text=""
      title="Edit Past Medical History"
      @confirm="performStore"
      @toggleDialog="toggleDialog"
    >
      <v-container class="no-padding-bottom no-padding-top">
        <v-row>
          <v-col cols="12">
            <BaseInput
              v-model="name"
              :errors="nameErrors"
              :placeholder="$t('general.inputs.height')"
              fieldName="name"
              fullWidth
              @blur="$v.name.$touch()"
              @change="onFieldChange"
            />
          </v-col>
          <v-col cols="12">
            <DatePicker
              v-model="date"
              :dense="true"
              :height="47"
              :hideDetails="true"
              :maxDate="new Date().toISOString().substr(0, 10)"
              :placeholder="$t('general.inputs.date')"
              @blur="$v.date.$touch()"
              @setDate="onChangeDate"
            />
          </v-col>
        </v-row>
      </v-container>
    </Confirm>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";
import { required } from "vuelidate/lib/validators";

import { PatientsApi } from "@/api/patients";
import Confirm from "@/components/shared/Confirm";
import DatePicker from "@/components/shared/DatePicker";
import BaseInput from "@/components/uikit/BaseInput";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";
import { useMediaStore } from "@/pinia-store/media";

export default {
  name: "PatientBodyHeightEdit",
  props: ["value", "editItem", "patientId"],
  components: {
    DatePicker,
    Confirm,
    BaseInput,
  },
  data: () => ({
    loading: false,
    id: "",
    date: new Date(),
    name: "",
  }),
  validations: {
    name: {
      required,
    },
    date: {
      required,
    },
  },
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    isSubmitDisabled() {
      return Boolean(!this.date || !this.value || this.dateErrors.length || this.valueErrors.length);
    },
    dateErrors() {
      const errors = [];
      if (!this.date) {
        if (!this.$v.date.$dirty) return errors;
      }
      const field = this.$t("general.inputs.date");
      !this.$v.date.required && errors.push(this.$t("validation.required", { field }));
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      const field = this.$t("general.inputs.height");
      !this.$v.name.required && errors.push(this.$t("validation.required", { field }));
      return errors;
    },
    localPatientId: {
      get() {
        return this.patientId;
      },
      set(newValue) {
        this.$emit("update:patientId", newValue);
      },
    },
  },
  watch: {
    editItem(newValue) {
      if (newValue) {
        this.id = newValue?.id;
        this.name = newValue?.name?.toString() || "";
        this.date = new Date(newValue?.date);
      }
    },
  },
  methods: {
    ...mapActions(useMediaStore, ["setUid"]),
    onFieldChange(value, fieldName) {
      this[fieldName] = value;
    },
    async onPatientSelect(patientId) {
      this.localPatientId = patientId;
      this.files = [];
      await this.setUid(patientId);
    },
    onChangeDate(e) {
      this.date = e;
    },
    toggleDialog() {
      this.$emit("toggleDialog");
    },
    toggleSuccessDialog() {
      this.successDialog = !this.successDialog;
    },
    async performStore() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        this.loading = true;
        const body = { id: this.id, value: this.name, date: this.date };
        if (this.localPatientId) {
          await PatientsApi.updateBodyHeight(body, this.localPatientId);
          this.$v.$reset();
        }
      } catch (err) {
        console.error(err);
        if (err && err.response && err.response.status === 409) {
          snackBarEventBus.$emit(snackBarEventName, {
            message: this.$t("general.errors.err"),
            type: "error",
          });
        }
      }
      this.$emit("onChange");
      this.toggleDialog();
      this.loading = false;
    },
  },
};
</script>
<style lang="scss">
.media-rename {
  .card-title {
    font-weight: 500 !important;
    font-size: 25px !important;
    line-height: 31px !important;
    color: #25233a;
  }

  .v-card__title + .v-card__text {
    font-size: 14px;
    line-height: 17px;
    color: #33343d;
  }
}
</style>
