<template>
  <div class="patient-body-height">
    <v-data-table
      v-if="loaded"
      :class="`table data-table data-table__green-border table-bordered ${className}`"
      :headers="headers"
      :height="'auto'"
      :items="tableItems"
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template v-slot:item.action="{ item }">
        <div class="table_actions">
          <v-menu content-class="patient-body-height__edit-menu" left offset-y top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
            </template>
            <v-list>
              <v-list-item @click="editItem(item)">
                <v-list-item-title class="patient-body-height__edit action-btn">
                  {{ $t("general.edit") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteItem(item.id)">
                <v-list-item-title class="patient-body-height__delete action-btn">
                  {{ $t("general.delete") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>

      <template v-slot:item.name="{ item }">
        {{ item.name }}
      </template>
      <template v-slot:item.date="{ item }">
        {{ item.date }}
      </template>
      <template v-slot:no-data>
        <span class="data-table__no-items">
          {{ $t("general.table.noItems") }}
        </span>
      </template>
    </v-data-table>
    <v-progress-linear v-if="loading" color="primary" indeterminate />
    <v-row :class="`patient-body-height__form ${largeLayout && 'large'}`" dense>
      <v-col cols="12">
        <div class="label">{{ $t("general.inputs.height") }}:</div>
      </v-col>
      <v-col :md="8" cols="12">
        <BaseInput
          v-model="value"
          :errors="valueErrors"
          :placeholder="$t('general.inputs.height')"
          fieldName="value"
          @blur="$v.value.$touch()"
          @change="onFieldChange"
        />
      </v-col>
      <v-col :md="largeLayout ? 2 : 4" cols="12">
        <DatePicker
          v-model="date"
          :dense="true"
          :height="47"
          :hideDetails="true"
          :maxDate="new Date().toISOString().substr(0, 10)"
          :placeholder="$t('general.inputs.date')"
          @blur="$v.date.$touch()"
          @setDate="onChangeDate"
        />
      </v-col>
      <v-col :class="`${largeLayout && 'justify-content-end'} `" :md="largeLayout ? 2 : 12" cols="12">
        <OutlinedButton
          :disabled="isSubmitDisabled"
          :fullWidth="true"
          :text="id ? $t('general.update') : $t('patient.addHeight')"
          additionalClassName="outlined-btn__green submit-btn"
          size="large"
          @onClick="onSubmit"
        />
      </v-col>
    </v-row>
    <PatientBodyHeightEdit
      v-model="editDialog"
      :editItem="editableItem"
      :patientId="patientId"
      @onChange="toggleEditDialog"
      @toggleDialog="toggleEditDialog"
      @update:patientId="patientId = $event"
    />
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";
import { required } from "vuelidate/lib/validators";

import { PatientsApi } from "@/api/patients";
import DatePicker from "@/components/shared/DatePicker";
import OutlinedButton from "@/components/uikit/OutlinedButton";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";
import { useCheckoutStore } from "@/pinia-store/checkout";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";

import BaseInput from "../uikit/BaseInput";
import PatientBodyHeightEdit from "./Edit";

export default {
  name: "PatientBodyHeightTable",
  props: {
    className: {},
    largeLayout: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    PatientBodyHeightEdit,
    DatePicker,
    OutlinedButton,
    BaseInput,
  },
  validations: {
    value: {
      required,
    },
    date: {
      required,
    },
  },
  data: function () {
    return {
      editDialog: false,
      editableItem: {},
      loading: true,
      loaded: false,
      id: "",
      date: new Date(),
      value: "",
      patientId: {},
    };
  },
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useProfileSettingsStore, ["bodyHeights"]),
    ...mapState(useCheckoutStore, ["height"]),
    isSubmitDisabled() {
      return Boolean(!this.date || !this.value || this.dateErrors.length || this.valueErrors.length);
    },
    tableItems() {
      return this.bodyHeights?.map
        ? this.bodyHeights.map((item) => ({
            id: item.id,
            name: item.value,
            date: moment(item.date).format("YYYY/MM/DD"),
          }))
        : [];
    },
    headers() {
      return [
        {
          text: this.$t("general.inputs.height"),
          value: "name",
          width: "60%",
        },
        {
          text: this.$t("general.inputs.date"),
          width: "30%",
          value: "date",
          align: "end",
        },
        { text: "", value: "action", width: 28 },
      ];
    },
    dateErrors() {
      const errors = [];
      if (!this.date) {
        if (!this.$v.date.$dirty) return errors;
      }
      const field = this.$t("general.inputs.date");
      !this.$v.date.required && errors.push(this.$t("validation.required", { field }));
      return errors;
    },
    valueErrors() {
      const errors = [];
      if (!this.$v.value.$dirty) return errors;
      const field = this.$t("general.inputs.height");
      !this.$v.value.required && errors.push(this.$t("validation.required", { field }));
      return errors;
    },
  },
  methods: {
    ...mapActions(useProfileSettingsStore, ["getBodyHeights"]),
    ...mapActions(useProfileSettingsStore, ["setBodyHeights"]),
    onFieldChange(value, fieldName) {
      this[fieldName] = value;
    },
    async toggleEditDialog() {
      this.editDialog = false;
      await this.getBodyHeights();
    },
    editItem(item) {
      this.editableItem = { ...item };
      setTimeout(() => (this.editDialog = true), 500);
    },
    async deleteItem(id) {
      this.loading = true;
      try {
        await PatientsApi.destroyBodyHeight(id, this.uid);
        await this.getBodyHeights();
      } catch (err) {
        console.error(err);
        if (err && err.response && err.response.status === 409) {
          snackBarEventBus.$emit(snackBarEventName, {
            message: this.$t("general.errors.err"),
            type: "error",
          });
        }
      }
      this.loading = false;
    },
    onChangeDate(e) {
      this.date = e;
    },
    async onSubmit() {
      this.loading = true;
      try {
        let method = "createBodyHeight";
        const body = { value: this.value, date: this.date };
        if (this.id) {
          body.id = this.id;
          method = "updateBodyHeight";
        }
        let data;
        if (this.patientId) {
          data = await PatientsApi[method](body, this.patientId);
        } else {
          data = await PatientsApi[method](body, this.uid);
        }
        const newData = [...this.bodyHeights];
        newData.push(data);
        this.bodyHeights.push(data);
        this.id = "";
        this.value = "";
        this.$v.$reset();
        await this.getBodyHeights();
      } catch (err) {
        console.error(err);
        if (err && err.response && err.response.status === 409) {
          snackBarEventBus.$emit(snackBarEventName, {
            message: this.$t("general.errors.err"),
            type: "error",
          });
        }
      }
      this.loading = false;
    },
  },
  async mounted() {
    await this.getBodyHeights();
    this.loaded = true;
    this.loading = false;
  },
};
</script>

<style lang="scss">
.outlined-btn__green {
  color: var(--v-success-base);

  &:not(:disabled) .v-btn__content {
    color: var(--v-success-base);
  }
}

.patient-body-height {
  th {
    background: #fafbfd !important;
  }

  &__edit-menu {
    .v-list {
      padding-top: 0;
      padding-bottom: 0;
    }

    text-align: center;
    background: #ffffff;
    border: 1px solid #eeeff7;
    box-shadow: none;
    box-sizing: border-box;
    border-radius: 4px;

    .v-list-item:not(:last-child) {
      border-bottom: 1px solid #eeeff7;
    }

    .v-list-item:hover {
      cursor: pointer;
      background: rgba(34, 148, 242, 0.08);
    }
  }

  &__edit {
    color: $primary;
  }

  &__delete {
    color: $error;
  }

  &__form {
    .label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #33343e;
      opacity: 0.88;
    }

    label .v-text-field__details {
      display: none;
    }

    padding: 16px 24px;

    &.large {
      @media (min-width: 767.98px) {
        padding: 16px 0;
      }
    }
  }
}
</style>
