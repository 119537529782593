<template>
  <div>
    <Chat class="chat-container" @action="action" :class="{ 'chat-container-mobile': $vuetify.breakpoint.xsOnly }" />
    <v-dialog v-model="dialog" max-width="463" :fullscreen="$vuetify.breakpoint.xsOnly" persistent>
      <PatientStartNewConversation v-if="dialog" @close="dialog = false" />
    </v-dialog>
    <v-dialog v-model="updateSettingDialog" max-width="900" :fullscreen="$vuetify.breakpoint.xsOnly" persistent>
      <PatientChatBotSettings v-if="updateSettingDialog" @close="updateSettingDialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import Chat from "@/components/Chat/Index.vue";
import PatientChatBotSettings from "@/components/settings/patientChatBot";
import PatientStartNewConversation from "@/views/Patient/Chat/PatientStartNewConversation";

export default {
  name: "patientChat",
  components: {
    PatientStartNewConversation,
    PatientChatBotSettings,
    Chat,
  },
  data() {
    return {
      dialog: false,
      updateSettingDialog: false,
    };
  },
  methods: {
    action(val) {
      if (this[val]) {
        this[val]();
      }
    },
    startConversation() {
      this.dialog = true;
    },
    updateInformation() {
      this.updateSettingDialog = true;
    },
  },
};
</script>
