<template>
  <div>
    <Confirm
      :confirmText="$t('general.update')"
      :dialog="value"
      :loading="loading"
      cancelText="Close"
      rootClass="media-rename"
      text=""
      title="Edit Past Medical History"
      @confirm="performStore"
      @toggleDialog="toggleDialog"
    >
      <v-container class="no-padding-bottom no-padding-top">
        <v-row>
          <v-col cols="12">
            <Select
              :hideDetails="true"
              :items="options"
              :label="$t('settings.profile.smokingStatus')"
              :value="selectedOption"
              class="select"
              item-text="name"
              item-value="id"
              @change="onSelect"
            />
          </v-col>
          <v-col cols="12">
            <DatePicker
              v-model="date"
              :dense="true"
              :height="47"
              :hideDetails="true"
              :maxDate="new Date().toISOString().substr(0, 10)"
              :placeholder="$t('general.inputs.date')"
              @blur="$v.date.$touch()"
              @setDate="onChangeDate"
            />
          </v-col>
        </v-row>
      </v-container>
    </Confirm>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";
import { required } from "vuelidate/lib/validators";

import { PatientsApi } from "@/api/patients";
import { TerminologyApi } from "@/api/terminology";
import Confirm from "@/components/shared/Confirm";
import DatePicker from "@/components/shared/DatePicker";
import Select from "@/components/uikit/Select";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";
import { useMediaStore } from "@/pinia-store/media";

export default {
  name: "PatientSmokingBehaviorEdit",
  props: ["value", "editItem", "patientId"],
  components: {
    Select,
    DatePicker,
    Confirm,
  },
  data: () => ({
    loading: false,
    options: [],
    code: "",
    id: "",
    date: new Date(),
    name: "",
  }),
  validations: {
    name: { required },
    code: { required },
    date: { required },
  },
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    selectedOption() {
      const index = this.options.findIndex && this.options.findIndex((i, index) => i.code === this.code);
      return index !== -1 ? parseInt(this.options[index].id) : "";
    },
    isSubmitDisabled() {
      return Boolean(!this.date || !this.value || this.dateErrors.length || this.valueErrors.length);
    },
    dateErrors() {
      const errors = [];
      if (!this.date) {
        if (!this.$v.date.$dirty) return errors;
      }
      const field = this.$t("general.inputs.date");
      !this.$v.date.required && errors.push(this.$t("validation.required", { field }));
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      const field = this.$t("general.inputs.height");
      !this.$v.name.required && errors.push(this.$t("validation.required", { field }));
      return errors;
    },
  },
  watch: {
    editItem(newValue) {
      if (newValue) {
        this.id = newValue?.id;
        this.code = newValue?.code;
        this.name = newValue?.name?.toString() || "";
        this.date = new Date(newValue?.date);
      }
    },
  },
  methods: {
    ...mapActions(useMediaStore, ["setUid"]),
    onSelect(value) {
      const index = this.options.findIndex && this.options.findIndex((i) => i.id === value);
      if (index !== -1) {
        this.name = this.options[index].name;
        this.code = this.options[index].code;
      }
    },
    onFieldChange(value, fieldName) {
      this[fieldName] = value;
    },
    onChangeDate(e) {
      this.date = e;
    },
    toggleDialog() {
      this.$emit("toggleDialog");
    },
    async performStore() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        this.loading = true;
        const body = { id: this.id, name: this.name, code: this.code, date: this.date };
        if (this.patientId) {
          await PatientsApi.updateSmokingBehavior(body, this.patientId);
          this.$v.$reset();
        }
      } catch (err) {
        console.error(err);
        if (err && err.response && err.response.status === 409) {
          snackBarEventBus.$emit(snackBarEventName, {
            message: this.$t("general.errors.err"),
            type: "error",
          });
        }
      }
      this.$emit("onChange");
      this.toggleDialog();
      this.loading = false;
    },
  },
  async mounted() {
    this.options = await TerminologyApi.getSmokingBehavior();
    this.loaded = true;
    this.loading = false;
  },
};
</script>
<style lang="scss">
.media-rename {
  .card-title {
    font-weight: 500 !important;
    font-size: 25px !important;
    line-height: 31px !important;
    color: #25233a;
  }

  .v-card__title + .v-card__text {
    font-size: 14px;
    line-height: 17px;
    color: #33343d;
  }
}
</style>
