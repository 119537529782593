<template>
  <div class="pscb_container">
    <div class="pscb_header">
      <span class="heading-2">Update General and Medical Information </span>
      <div class="vertical-separator" />
      <PrimaryButton
        :disabled="buttonLoading"
        :text="$t('settings.profile.discard')"
        class-name="discard-button"
        @onClick="close"
      />
      <PrimaryButton :loading="buttonLoading" :text="$t('settings.profile.save')" @onClick="saveChanges" />
    </div>
    <div class="pscb_divider"></div>
    <v-card outlined>
      <v-progress-linear v-if="loading" color="primary" indeterminate />
      <div class="pscb_details">
        <span class="heading-4">{{ $t("settings.profile.generalMedicalInformation") }}</span>
        <v-row class="pscb_row">
          <v-col class="pscb_input-group" cols="12" md="6">
            <PatientBodyHeightTable />
          </v-col>
          <v-col class="pscb_input-group" cols="12" md="6">
            <PatientBodyWeightTable />
          </v-col>
          <v-col class="pscb_input-group" cols="12" md="6">
            <PatientSmokingBehaviorTable />
          </v-col>
        </v-row>
      </div>
      <div class="pscb_details">
        <span class="heading-4 pscb_margin-bottom">{{ $t("settings.profile.pastMedicalHistory") }}</span>
        <PatientHistoryTable
          id="PMH"
          :items="pmh"
          place="visitNotes"
          tableClassName="ph-table_visit-notes_table"
          @change="onPMHChange"
        />
      </div>
      <div class="pscb_details">
        <span class="heading-4 pscb_margin-bottom">{{ $t("settings.profile.allergies") }}</span>
        <Combobox :items="allergies" placeholder="Enter allergies" @change="onAllergiesChange" />
      </div>
      <div class="pscb_details">
        <span class="heading-4 pscb_margin-bottom">{{ $t("settings.profile.medications") }}</span>
        <MedicationTable
          :items="medications"
          place="visitNotes"
          tableClassName="medications-table_visit-notes_table"
          @change="onMedicalInfoChange"
        />
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";
import { email, required } from "vuelidate/lib/validators";

import MedicationTable from "@/components/Medications/Table";
import PatientBodyHeightTable from "@/components/PatientBodyHeightTable/Table";
import PatientBodyWeightTable from "@/components/PatientBodyWeightTable/Table";
import PatientHistoryTable from "@/components/PatientHistoryTable/Table";
import PatientSmokingBehaviorTable from "@/components/PatientSmokingBehaviorTable/Table";
import Combobox from "@/components/uikit/Combobox";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";
import { defaultLocale } from "@/utils/constants";

export default {
  name: "PatientChatBotSettings",
  components: {
    PatientBodyHeightTable,
    PatientBodyWeightTable,
    PatientSmokingBehaviorTable,
    PrimaryButton,
    MedicationTable,
    PatientHistoryTable,
    Combobox,
  },
  validations: {
    email: { required, email },
    firstName: { required },
    lastName: { required },
    dateOfBirth: { required },
    homePhoneNumber: { required },
  },
  data: () => ({
    loading: false,
    buttonLoading: false,
    firstNameModel: "",
    lastNameModel: "",
    emailModel: "",
    dateOfBirthModel: null,
    stateModel: "",
    homePhoneNumberModel: "",
    phoneNumberModel: "",
    addressModel: "",
    heightModel: "",
    weightModel: "",
    isSmokingModel: "false",
    localeModel: defaultLocale,
  }),
  computed: {
    ...mapState(useProfileSettingsStore, ["patientSettings", "avatar", "generalInformation", "medicalInformation"]),
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(this.$t("validation.email"));
      !this.$v.email.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.email") }));
      return errors;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.firstName") }));
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.lastName") }));
      return errors;
    },
    medications() {
      if (!this.medicalInformation || !this.medicalInformation.medications) {
        return [];
      }
      return this.medicalInformation.medications.map((el) => ({
        ...el,
        startDate: new Date(el.startDate),
      }));
    },
    allergies() {
      if (!this.medicalInformation || !this.medicalInformation.allergies) {
        return [];
      }
      return this.medicalInformation.allergies;
    },
    pmh() {
      if (!this.medicalInformation || !this.medicalInformation.pmh) {
        return [];
      }
      return this.medicalInformation.pmh;
    },
    firstName: {
      get() {
        return this.firstNameModel || this.patientSettings.firstName;
      },
      set(v) {
        this.firstNameModel = v;
      },
    },
    lastName: {
      get() {
        return this.lastNameModel || this.patientSettings.lastName;
      },
      set(v) {
        this.lastNameModel = v;
      },
    },
    email: {
      get() {
        return this.emailModel || this.patientSettings.email;
      },
      set(v) {
        this.emailModel = v;
      },
    },
    dateOfBirth: {
      get() {
        return this.dateOfBirthModel
          ? this.dateOfBirthModel
          : this.patientSettings.birthDate
          ? new Date(this.patientSettings.birthDate)
          : new Date();
      },
      set(v) {
        this.dateOfBirthModel = v;
      },
    },
    state: {
      get() {
        return this.stateModel || this.patientSettings.state;
      },
      set(v) {
        this.stateModel = v;
      },
    },
    address: {
      get() {
        return this.addressModel || this.patientSettings.address;
      },
      set(v) {
        this.addressModel = v;
      },
    },
    phoneNumber: {
      get() {
        return this.phoneNumberModel || this.patientSettings.phoneNumber;
      },
      set(v) {
        this.phoneNumberModel = v;
      },
    },
    homePhoneNumber: {
      get() {
        return this.homePhoneNumberModel || this.patientSettings.homePhoneNumber;
      },
      set(v) {
        this.homePhoneNumberModel = v;
      },
    },
    weight: {
      get() {
        return this.weightModel || this.generalInformation.weight;
      },
      set(v) {
        this.weightModel = v;
      },
    },
    height: {
      get() {
        return this.heightModel || this.generalInformation.height;
      },
      set(v) {
        this.heightModel = v;
      },
    },
    isSmoking: {
      get() {
        return this.isSmokingModel || this.generalInformation.isSmoking;
      },
      set(v) {
        this.isSmokingModel = v;
      },
    },
    locale: {
      get() {
        return this.localeModel || this.patientSettings.locale;
      },
      set(v) {
        this.localeModel = v;
      },
    },
  },
  methods: {
    ...mapActions(useProfileSettingsStore, ["savePatientChanges"]),
    ...mapActions(useProfileSettingsStore, ["setMedicalInformation"]),
    changeCurrentDate(newDate) {
      this.dateOfBirth = newDate;
    },
    close() {
      this.$emit("close");
    },
    onAllergiesChange(value) {
      this.setMedicalInformation({ value, fieldName: "allergies" });
    },
    onMedicalInfoChange(value) {
      this.setMedicalInformation({ value, fieldName: "medications" });
    },
    onPMHChange(value) {
      this.setMedicalInformation({ value, fieldName: "pmh" });
    },
    async saveChanges() {
      this.buttonLoading = true;
      try {
        await this.savePatientChanges({
          locale: this.locale,
          profile: {
            name: { firstName: this.firstName, lastName: this.lastName },
            address: { address: this.address, state: this.state },
            birthDate: this.dateOfBirth.toISOString(),
            homePhoneNumber: this.homePhoneNumber,
            phoneNumber: this.phoneNumber,
          },
          generalInformation: {
            height: this.height,
            weight: this.weight,
            isSmoking: this.isSmoking,
          },
        });
        this.$root.$i18n.locale = this.locale;
        snackBarEventBus.$emit(snackBarEventName, {
          message: this.$t("settings.success"),
          type: "success",
        });
      } catch (err) {
        console.error(err);
        snackBarEventBus.$emit(snackBarEventName, {
          message: (err && err.response && err.response.data && err.response.data.message) || err,
          type: "error",
        });
      } finally {
        this.close();
        this.buttonLoading = false;
      }
    },
  },
  mounted() {
    this.loading = true;
  },
  updated() {
    if (this.patientSettings.firstName) this.loading = false;
  },
};
</script>
