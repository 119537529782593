<template>
  <div class="patient-smoking-behavior">
    <v-data-table
      v-if="loaded && tableItems.length"
      :class="`table data-table data-table__green-border table-bordered ${className}`"
      :fixed-header="true"
      :headers="headers"
      :height="tableItems.length > 3 ? 210 : 'auto'"
      :items="tableItems"
      disable-sort
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template v-slot:item.action="{ item }">
        <div class="table_actions">
          <v-menu content-class="patient-smoking-behavior__edit-menu" left offset-y top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
            </template>
            <v-list>
              <v-list-item @click="editItem(item)">
                <v-list-item-title class="patient-smoking-behavior__edit action-btn">
                  {{ $t("general.edit") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteItem(item.id)">
                <v-list-item-title class="patient-smoking-behavior__delete action-btn">
                  {{ $t("general.delete") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>

      <template v-slot:item.name="{ item }">
        <div class="table_actions">
          {{ item.name }}
        </div>
      </template>
      <template v-slot:item.date="{ item }">
        <div class="table_actions">
          {{ item.date }}
        </div>
      </template>
      <template v-slot:no-data>
        <span class="data-table__no-items">
          {{ $t("general.table.noItems") }}
        </span>
      </template>
    </v-data-table>
    <v-progress-linear v-if="loading" color="primary" indeterminate />
    <v-row :class="`patient-smoking-behavior__form ${largeLayout && 'large'}`" dense>
      <v-col cols="12" md="8">
        <Select
          :hideDetails="true"
          :items="options"
          :label="$t('settings.profile.smokingStatus')"
          :value="selectedOption"
          class="select"
          item-text="name"
          item-value="id"
          @change="onSelect"
        />
      </v-col>
      <v-col :md="largeLayout ? 2 : 4" cols="12">
        <DatePicker
          v-model="date"
          :dense="true"
          :height="47"
          :hideDetails="true"
          :maxDate="new Date().toISOString().substr(0, 10)"
          :placeholder="$t('general.inputs.date')"
          @blur="$v.date.$touch()"
          @setDate="onChangeDate"
        />
      </v-col>
      <v-col :class="`${largeLayout && 'justify-content-end'} `" :md="largeLayout ? 2 : 12" cols="12">
        <OutlinedButton
          :disabled="isSubmitDisabled"
          :fullWidth="true"
          :text="id ? $t('general.update') : $t('patient.addStatus')"
          additionalClassName="outlined-btn__green submit-btn"
          size="large"
          @onClick="onSubmit"
        />
      </v-col>
    </v-row>
    <PatientSmokingBehaviorEdit
      v-model="editDialog"
      :editItem="editableItem"
      :patientId="patientId"
      @onChange="toggleEditDialog"
      @toggleDialog="toggleEditDialog"
    />
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";
import { required } from "vuelidate/lib/validators";

import { PatientsApi } from "@/api/patients";
import DatePicker from "@/components/shared/DatePicker";
import OutlinedButton from "@/components/uikit/OutlinedButton";
import Select from "@/components/uikit/Select";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";
import { useCheckoutStore } from "@/pinia-store/checkout";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";
import { useTerminologyStore } from "@/pinia-store/terminology";

import PatientSmokingBehaviorEdit from "./Edit";

export default {
  name: "PatientSmokingBehaviorTable",
  props: {
    className: {},
    patientId: {},
    largeLayout: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    PatientSmokingBehaviorEdit,
    DatePicker,
    OutlinedButton,
    Select,
  },
  validations: {
    name: { required },
    code: { required },
    date: { required },
  },

  data: function () {
    return {
      editDialog: false,
      editableItem: {},
      loading: true,
      loaded: false,
      id: "",
      date: new Date(),
      code: "",
      name: "",
      value: "",
    };
  },
  computed: {
    ...mapActions(useTerminologyStore, { options: "smokingBehavior" }),
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useProfileSettingsStore, ["smokingBehaviors"]),
    ...mapState(useCheckoutStore, ["isSmoking"]),
    tableItems() {
      return this.smokingBehaviors?.map
        ? this.smokingBehaviors.map((item) => ({
            id: item.id,
            name: item.name,
            date: moment(item.date).format("YYYY/MM/DD"),
          }))
        : [];
    },
    headers() {
      return [
        {
          text: this.$t("settings.profile.smokingStatus"),
          value: "name",
          width: "60%",
        },
        {
          text: this.$t("general.inputs.date"),
          width: "30%",
          value: "date",
          align: "end",
        },
        { text: "", value: "action", width: 28 },
      ];
    },
    isSubmitDisabled() {
      return !this.code;
    },
    dateErrors() {
      const errors = [];
      if (!this.date) {
        if (!this.$v.date.$dirty) return errors;
      }
      const field = this.$t("general.inputs.date");
      !this.$v.date.required && errors.push(this.$t("validation.required", { field }));
      return errors;
    },
    selectedOption() {
      const index = this.options.findIndex && this.options.findIndex((i, index) => i.code === this.code);
      return index !== -1 ? parseInt(this.options[index].id) : "";
    },
  },
  methods: {
    ...mapActions(useTerminologyStore, ["preloadSmokingBehavior"]),
    ...mapActions(useProfileSettingsStore, ["getSmokingBehaviors"]),
    ...mapActions(useProfileSettingsStore, ["setSmokingBehaviors"]),
    async toggleEditDialog() {
      this.editDialog = false;
      await this.getSmokingBehaviors();
    },
    onFieldChange(value, fieldName) {
      this[fieldName] = value;
    },
    onSelect(value) {
      const index = this.options.findIndex((i) => i.id === value);
      if (index !== -1) {
        this.name = this.options[index].name;
        this.code = this.options[index].code;
      }
    },

    onChangeDate(e) {
      this.date = e;
    },
    async onSubmit() {
      this.loading = true;
      try {
        let method = "createSmokingBehavior";
        const body = { name: this.name, code: this.code, date: this.date };
        if (this.id) {
          body.id = this.id;
          method = "updateSmokingBehavior";
        }
        let data;
        if (this.patientId) {
          data = await PatientsApi[method](body, this.patientId);
        } else {
          data = await PatientsApi[method](body, this.uid);
        }
        const newData = [...this.smokingBehaviors];
        this.name = "";
        this.code = "";
        newData.push(data);
        this.smokingBehaviors.push(data);
        await this.getSmokingBehaviors();
      } catch (err) {
        console.error(err);
        if (err && err.response && err.response.status === 409) {
          snackBarEventBus.$emit(snackBarEventName, {
            message: this.$t("general.errors.err"),
            type: "error",
          });
        }
      }
      this.loading = false;
    },
    editItem(item) {
      const index = this.smokingBehaviors.findIndex((it) => it.name === item.name);
      this.editableItem = { ...item, code: index !== -1 ? this.smokingBehaviors[index].code : item.code };
      setTimeout(() => (this.editDialog = true), 500);
    },
    async deleteItem(id) {
      this.loading = true;

      try {
        await PatientsApi.destroySmokingBehavior(id, this.uid);
        await this.getSmokingBehaviors();
      } catch (err) {
        console.error(err);
        if (err && err.response && err.response.status === 409) {
          snackBarEventBus.$emit(snackBarEventName, {
            message: this.$t("general.errors.err"),
            type: "error",
          });
        }
      }
      this.loading = false;
    },
  },
  async mounted() {
    await this.getSmokingBehaviors();
    await this.preloadSmokingBehavior();
    this.loaded = true;
    this.loading = false;
  },
};
</script>
<style lang="scss" scoped>
.patient-smoking-behavior {
  &__edit-menu {
    .v-list {
      padding-top: 0;
      padding-bottom: 0;
    }

    text-align: center;
    background: #ffffff;
    border: 1px solid #eeeff7;
    box-shadow: none;
    box-sizing: border-box;
    border-radius: 4px;

    .v-list-item:not(:last-child) {
      border-bottom: 1px solid #eeeff7;
    }

    .v-list-item:hover {
      cursor: pointer;
      background: rgba(34, 148, 242, 0.08);
    }
  }

  &__edit {
    color: $primary;
  }

  &__delete {
    color: $error;
  }

  &__form {
    .label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #33343e;
      opacity: 0.88;
    }

    label .v-text-field__details {
      display: none;
    }

    padding: 16px 24px;

    &.large {
      @media (min-width: 767.98px) {
        padding: 16px 0;
      }
    }
  }
}
</style>
